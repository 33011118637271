@import 'colors';

.ant-popover {
  .car-logs {
    min-width: 320px;
    min-height: 48px;

    .field {
      white-space: nowrap;

      .label {
        font-weight: 500;
        margin-right: 6px;
      }

      .last-entry {
        color: $antd-blue-active;
      }

      .date {
        &.empty {
          color: gray;
        }

        &.outdated {
          color: $red;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
    }
  }

}
