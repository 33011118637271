@import 'colors';

.access-item-form {
  padding-top: 4px;

  .areas {

  }

  .user-info {
    display: flex;
    align-items: flex-start;
    gap: 1em;

    .label-container {
      flex: 1 0 auto;
    }

    .phone-number {
      width: 220px;

      .ant-input-affix-wrapper {
        width: 100%;
      }
    }


    .tenant-container {
      padding-left: 6px;
      padding-top: 4px;
    }


  }

  .cars {
    min-width: 500px;
  }

  &.is-edit {
    .phone-number {
      width: 180px;
      min-width: 180px;

      > .ant-typography {
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
      }

    }
  }
}

