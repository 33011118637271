
.gates-log {
	.upload-btn {
		background-color: #7361d3;

		&:hover,
		&:focus {
			background-color: rgb(128, 108, 255);
		}
	}

	.filter-form {
		.filter-fields {
			.filter-field {
				.ant-picker,
				.ant-select {
					width: 100%
				}
			}
		}
	}

	.ant-table {

		td {

			&.dateTime {
				width: 150px;
			}

			&.dateTime {
				.time {
					color: #185dbd;
				}
			}
		}
	}
}

