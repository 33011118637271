@import 'colors.scss';

$cardWidth: 460px;

$c: #c7c7c7;

.flat-info-card.ant-card {
  width: $cardWidth;
  min-width: $cardWidth;

  max-width: 700px;

  .card-title.flat-title {
    display: flex;
    justify-content: flex-end;
    gap: 1em;

    > h5 {
      margin: 0;
    }

    .address {
      font-weight: normal;
      color: gray;
      flex: 1 0 auto;
      text-align: end;
    }
  }

  .ant-card-body {
    padding: 6px 8px;
  }

  .flat-accesses {
    padding-top: 2em;

    .accesses-list {

      &.same-length {
        &.length-1 {
          .access-item {
            .access-info {
              .access-item-areas {
                width: 26px;
              }

            }
          }
        }
      }
    }

    .access-header {
      display: flex;
      gap: 0.5em;
      align-items: center;

      h5 {
        margin: 0;
      }

      .ant-btn-link.add-btn {
        @include linkButtonStyle();
        padding: 0;
        height: 22px;
        line-height: 22px;

        .anticon {
          font-size: 12px;
        }
      }
    }

    .ant-empty {
      margin-top: 1em;

      .ant-empty-image {
        height: 64px;
      }
    }

    .no-available-areas {
      margin-top: 0.5em;

      .anticon {
        font-size: 16px;
        margin-right: 0.5em;
      }

      color: rgba(50, 50, 50, 0.8);
    }
  }

}


