.rooms {
	.filter-form {
		.filter-fields {
          //.filter-field {
          //	&.building {
          //		width: 120px;
          //	}
          //
          //	&.number {
          //		width: 150px;
          //	}
          //
          //	&.type {
          //		width: 180px;
          //	}
          //}
        }
	}


	.ant-table.ant-table-middle {
		td {
			&.type {
				.anticon {
					font-size: 20px;
				}

				.FLAT {
					color: #0958d9;

					.anticon {
						font-size: 18px;
					}
				}

				.GARAGE {
					color: #389e0d;
				}

				.OFFICE {
					color: #c41d7f;

					.anticon {
						font-size: 18px;
					}
				}
			}
		}

	}
}
