.buildings {
  min-height: 100%;
  padding: 10px;

  > h4 {
    margin: 0;
    margin-left: 8px;
    margin-bottom: 0.5em;
  }

  .search {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 4px;
    margin-bottom: 0.5em;

    .ant-input-affix-wrapper {
      &.phone-number {
        flex: 0 1 auto;
      }

      &.car-number {
        width: 150px;
        min-width: 150px;
      }
    }
  }

  .ant-list {
    .building {
      padding: 2px 4px;

      .ant-btn-link {
        &:not(:disabled) {
          color: rgba(0, 0, 0, 0.88);

          &:hover {
            color: #69b1ff;
          }

          &:active {
            color: #0958d9;
          }
        }
      }
    }
  }

  .flat-info {
    .ant-card {
      .ant-card-body {
        max-height: calc(100vh - 64px - 20px - 44px - 40px - 40px);
        overflow-y: auto;
      }
    }

  }
}
