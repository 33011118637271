.gates.view {
  .upload-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1000;
  }

  .filter-form {
    .filter-fields {
      .filter-field {
        .ant-select,
        .ant-picker {
          width: 100%;
        }

        &.gateId {
          .ant-select-selection-item {
            font-size: 16px;
            color: #eb2f96;
          }
        }
      }
    }
  }
}
