$border: 1px solid rgba(203, 203, 203, 0.6);


.building-plan {
  min-height: 300px;
  //border: 1px solid red;

  .header {
    margin-bottom: 12px;
    display: flex;
    padding-left: 12px;
    align-items: center;
    gap: 1em;

    .anticon {
      font-size: 20px;
    }

    h4 {
      margin: 0;
    }
  }

  .plan-container {
    display: flex;
    align-items: flex-start;
    gap: 2em;
  }

  &.UNDERGROUND_PARKING {
    .header {
      //padding-left: 18px;
    }
  }
}
