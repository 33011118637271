@import 'colors';

.app-table {
  padding: 2px 4px;
  position: relative;
  background-color: white;


  .pagination-container {
    //background: #c0d9f5;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .table-toolbar {
      flex: 1 0 auto;
    }
  }

  .ant-table-wrapper {
    margin-top: 12px;

    @include tableCellPaddingRule(4px 8px);

    .ant-table-thead {
      th {
        line-height: 18px;
      }
    }

    .ant-table-pagination.ant-pagination {
      margin: 0 4px 16px 0;
    }


    .ant-table.ant-table-small {
      line-height: 16px;

      .ant-table-tbody {
        > tr {
          > td {
            //padding: 4px 6px;
            //line-height: 16px;
            //text-align: start;

            > span.ant-typography {
              line-height: 16px;

              > div.ant-typography-copy {
                font-size: 13px;
              }
            }

            .ant-typography-copy {
              display: inline-block;
              //margin-left: 10px;
              visibility: hidden;
            }

            &:hover {
              .ant-typography-copy {
                visibility: visible;
                //color: rgba(10, 10, 10, .0 .7);

                &:hover {
                  color: #6694bd;
                }

                &:active {
                  color: #2763a1;
                }
              }
            }

            &.actions {
              text-align: center;

              .ant-btn {
                border: none;

                &:not(:last-child) {
                  margin-right: 6px;
                }

                &:not(:disabled) {
                  color: rgba(100, 100, 100, 0.7);

                  &:hover,
                  &:focus,
                  &:active {
                    color: $antd-blue-hover;
                  }

                }


              }
            }
          }
        }


      }
    }
  }

  .pagination_reload_btn {
    position: absolute;
    top: 0;
    left: 170px;
    //padding: 4px 8px;
  }

  &.empty.with-loading {
    .table {
      padding-top: 48px;
    }
  }
}
