@import "colors";

.ant-layout-header {
  padding: 0;
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  height: 48px;

  .app-title {
    flex: 1 0 auto;
    padding-left: 24px;
    font-size: 18px;
  }

  .company-name {
    font-size: 16px;
    color: rgba(240, 240, 240, 0.7);
  }

  .user-info {
    .workspace-avatar {
      margin-right: 4px;
    }
    .workspace-selector {
      font-size: 18px;
      margin-right: 1em;

      &:hover {
        color: #acd1fb;
        cursor: pointer;
      }

      &:active {
        cursor: pointer;
        color: $antd-blue;
      }
    }
    .ant-dropdown-trigger.user-name {
      color: $antd-blue-hover;
      font-size: 16px;
      margin-left: 1em;

      &:hover {
        color: #acd1fb;
      }

      &:active {
        color: $antd-blue;
      }

      .anticon {
        margin-right: 6px;
      }
    }

    padding-right: 12px;
  }
}
