@import "colors";

.expenses {
  position: relative;

  .ant-card {
    .ant-card-body {
      padding: 12px 16px;
    }
  }


  .ant-table-wrapper.expenses-table {
    @include tableCellPaddingRule();

    tr.ant-table-expanded-row {
      .ant-table-wrapper {
        @include tableCellPaddingRule();

        td {
          &.date,
          &.sum {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .payments-table {
    .ant-table.ant-table-small {
      line-height: 16px;

      tr.ant-table-row {
        th, td {
          padding: 2px 4px;

          &.toName {
            min-width: 160px;
          }

          &.date {
            width: 130px;
          }

          &.toInn {
            width: 120px;
          }

          &.toAccount {
            width: 200px;
          }

          &.bankName {
            min-width: 250px;
          }

          &.incomingSum {
            min-width: 100px;
          }
        }
      }
    }
  }
}

