//.gates.view {
//.gate-selector {
//	width: 200px;
//
//	.ant-select-selector {
//		//font-size: 16px;
//		.ant-select-selection-item {
//
//			color: #eb2f96;
//			//font-weight: 500;
//		}
//	}
//}


//.gates-log {
//
//	.ant-table {
//
//		td {
//
//			&.dateTime {
//				width: 150px;
//			}
//
//			&.dateTime {
//				.time {
//					color: #185dbd;
//				}
//			}
//		}
//	}
//}

.tops {

	.tables {
		display: flex;
		gap: 4em;

		.table {
			&.by-flat {
				width: 400px;
				}

				&.by-user {
					width: 600px;
				}

			}
		}

		//.ant-table-wrapper {
		//	width: 600px;
		//}
	}


