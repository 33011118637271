@import '../../../../../../colors';


.app-static-modal.access-add-modal {
  .title {
    //margin-bottom: 0.5em;
    font-weight: 500;

    //

    .phone-number {
      margin: 0 8px;
      color: #0c4daa
      //font-weight: 500;
    }

    .phone-label {
      font-weight: normal;
      font-size: 15px;
      color: rgba(80, 80, 80, 0.9);
    }
  }
}

.access-add-form {
  .access-author {
    text-align: end;
    color: rgba(60, 60, 60, 0.9);
  }

  //.ant-alert.ant-alert-info {
  //  padding: 8px 12px;
  //  //border-color:
  //  background-color: rgba(243, 248, 251, 0.8);
  //  border-color: rgba(146, 194, 238, 0.6);
  //
  //}

  h5 {
    margin-top: 18px;
    margin-bottom: 6px;

    &:first-child {
      margin-top: 4px;
    }
  }

  .sub-header {
    font-size: 15px;
    color: $antd-blue-active;

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      span.icon {
        margin-left: 4px;

        .anticon.icon-playground {
          font-size: 16px;
          color: rgb(88, 158, 116);
        }

        .anticon.parking-icon {
          color: rgb(119, 106, 195);
          //color: rgb(116, 113, 132);
        }
      }
    }
  }


  .ant-btn-link.add-btn {
    @include linkButtonStyle();
    margin: 4px 6px;
  }
}

