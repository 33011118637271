@import 'colors';

.counterparties.view {
  .add-btn {
    position: absolute;
    top: 4px;
    padding: 0;
    left: 4px;
    z-index: 100;
  }

  &.loading.no-data {
    //.add-btn {
    //  top: 0;
    //}

    .ant-table-wrapper {
      margin-top: 32px;
    }
  }

  .ant-table-wrapper {
    @include tableCellPaddingRule(4px 8px);

    .ant-pagination.ant-pagination-mini {
      margin: 4px 0;
    }

    .ant-table {
      tr {
        &:hover {
          cursor: pointer;

          td {
            //background: #6694bd;
          }
        }
      }
    }
  }

}

.ant-modal-confirm.ant-modal-confirm-info.counterparty-modal {
  .ant-modal-body {
    .anticon.anticon-info-circle {
      display: none;
    }

    .ant-modal-confirm-title {
      display: block;
      flex: none;
    }

    .ant-modal-confirm-btns {
      display: none;
    }

    .ant-modal-confirm-content {
      margin-inline-start: 4px;

      .field {

        margin-bottom: 12px;

      }

      .buttons {
        margin-top: 12px;
        text-align: end;

        .ant-btn:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
}
