@import "colors";

.flat-owner-info {
  .owner-name-container {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 6px;

    .owner-icon {
      font-size: 22px;
      color: #f0c101;
      //color: #2d71e0;
    }

    .owner-name {
      font-size: 16px;
      //margin-bottom: 0.5em;
      line-height: 16px;
      //color: #0958d9;
    }
  }

  .owner-property {
    margin-top: 4px;
    line-height: 18px;
    color: $grayMidDark;
  }

}

