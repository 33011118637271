$fieldPadding: 6px;

.filter-form {
  padding: 4px 8px;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 2px;
  background-color: rgb(250, 250, 250);

  margin-bottom: 20px;

  .buttons {
    display: flex;
    height: 100%;
    align-items: flex-end;
    gap: 1em;

    .ant-dropdown-button {
      width: auto;
    }

    .export_btn {
      margin-left: 20px;
      background-color: #7361d3;

      &:hover,
      &:focus {
        background-color: rgb(128, 108, 255);
      }
    }
  }

  .search_icon {
    font-size: 22px;
    padding-top: 10px;
  }

  .filter-field {
    .ant-select,
    .ant-input {
      width: 100%;
    }

    .ant-select .ant-select-selector,
    .ant-picker,
    .ant-input-affix-wrapper {
      padding-right: $fieldPadding;
      padding-left: $fieldPadding;

      .ant-select-selection-search {
        inset-inline-start: $fieldPadding;
        inset-inline-end: $fieldPadding;
      }
    }

    &.required {
      .label {
        &::after {
          content: ' *';
          color: rgba(226, 75, 75, 0.9);
        }
      }

      &.empty {
        .label {
          &::after {
            color: red;
          }
        }

        .ant-input,
        .ant-select-selector,
        .ant-picker {
          border-color: red;
          box-shadow: 0 0 0 1px rgba(249, 167, 167, 0.75);
        }

      }
    }
  }


  .field {
    .field_label {
      line-height: 16px;
    }
  }

  .extra_controls {
    padding-left: 40px;
  }

  .ant-upload-list-text-container {
    .ant-upload-list-item {
      margin-top: 0;
    }
  }
}
