$border: 1px solid rgba(203, 203, 203, 0.6);

.building-plan {
  display: inline-block;

  &.loading {
    width: 100%;
  }

  .floor {
    display: flex;
    align-items: center;

    position: relative;

    &:not(:last-child) {
    }

    .floor-number {
      padding: 2px;
      display: inline-block;
      font-size: 13px;
      text-align: center;
      width: 32px;
      line-height: 18px;
      min-height: 22px;
      color: rgb(52, 32, 173);

      &:not(:last-child) {
        //border-bottom: $border;
      }
    }

    .rooms {
      border: $border;
      border-bottom: none;
      border-top: none;

      .rooms-of-floor {
        white-space: nowrap;

        .flat-container {
          padding: 3px;
          display: inline-block;
          border-bottom: $border;

          .flat {
            height: 18px;
            line-height: 18px;
            width: 30px;
            font-size: 13px;
            //font-weight: 500;
            text-align: center;
            background: rgba(222, 222, 222, 0.3);
            transition: all .2s ease-in-out;


            &.OFFICE {
              background: rgba(235, 152, 247, 0.3);
            }

            &:hover {
              cursor: pointer;
              transform: scale(1.2);
              background: rgba(216, 232, 251, 0.89);
            }

            &:active {
              cursor: pointer;
              transform: scale(1.2);
              background: rgba(201, 216, 233, 0.89);
            }

            &.selected {
              background: rgb(190, 220, 253);
              transform: scale(1.2);
            }

            &:not(:last-child) {
              margin-right: 4px;
            }
          }
        }


      }
    }


    &:first-child {
      .rooms {
        border-top: $border;
      }
    }

  }

  &.UNDERGROUND_PARKING {
    .floor {
      .floor-number {
        font-size: 22px;
      }

      &:not(:last-child) {
        .rooms {
          .rooms-of-floor:last-child {
            border-bottom: 1px solid rgba(153, 153, 153, 0.6);
          }
        }
      }
    }
  }
}
