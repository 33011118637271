@import '../../../../../../../../colors';

.car-list {
  width: 100%;

  .add-btn {
    height: 18px;
    padding: 0;
    line-height: 18px;
  }

  .car-item {
    .ant-input,
    .ant-input-affix-wrapper {
      &.car-number {
        width: 140px;
      }

      &.car-description {
        width: 280px;
        margin-left: 6px;
      }

      &.invalid {
        border-color: $red;

        &:focus,
        &:global(.ant-picker-focused) {
          box-shadow: 0 0 0 2px rgba(242, 43, 43, 0.22);
        }
      }
    }

    .ant-btn-link.delete-btn {
      @include rowActionButtonStyle();

      .anticon {
        vertical-align: -0.125em;
      }

      padding: 0 4px;
      margin-left: 2px;
    }

    &:not(:first-child) {
      margin-top: 4px;
    }
  }
}
