.expence-params {
  .ant-card-body {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2em;

    &::before,
    &::after {
      display: none;
    }

    .ant-typography.label {
      font-weight: 500;
    }

    .grouping {
      .ant-radio-group {
        margin: 0;

        .ant-radio-wrapper {
          line-height: 24px;
        }
      }
    }

  }


}
