.expenses-chart {
  margin: 20px 0 10px;

  .header {
    text-align: center;

    .ant-statistic {
      .ant-statistic-content-value {
        color: rgb(255, 98, 0);
      }

    }
  }
}
