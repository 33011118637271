@import 'colors.scss';

.App {
  .ant-app {
    height: 100vh;

  }

  .ant-layout {
    height: 100%;
    background: rgba(255, 255, 255, 0.9);

    .ant-layout-content {
      padding: 4px 8px;
      overflow-y: auto;

      .view {
        position: relative;
        padding: 4px;
      }
    }

    .ant-layout-footer {
      height: 48px;
      padding: 13px 48px;
      text-align: center;
    }
  }

  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 2px;

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 4px 6px;
        }
      }
    }

  }
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-info.modal-no-btns,
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.modal-no-btns,
.ant-modal.ant-modal-confirm.ant-modal-confirm-info.app-static-modal {
  .anticon.anticon-exclamation-circle {
    display: none;
  }

  .ant-modal-content {
    padding: 8px 12px;
    padding-bottom: 12px;

    .ant-modal-close {
      top: 4px;
      right: 4px;
    }

    .ant-modal-body {
      .ant-modal-confirm-body {
        .anticon.anticon-info-circle {
          display: none;
        }

        .ant-modal-confirm-title {
          flex: 0 0 100%;
        }

        .ant-modal-confirm-content {
          margin-inline-start: 4px;
          min-height: 90px;
          max-width: 100%;

          .buttons,
          .modal-buttons,
          .footer {
            margin-top: 1.5em;
            text-align: end;

            .ant-btn {
              text-align: center;

              &:not(:last-child) {
                margin-right: 1em;
              }
            }
          }
        }
      }

      .ant-modal-confirm-btns {
        display: none;
      }
    }

  }
}

.anticon.leaf-icon {
  color: #61a938;
}
.ant-notification {
  .ant-notification-notice {
    padding: 12px 16px;

    .ant-divider {
      margin: 6px 0 12px 0;
    }

    a.ant-notification-notice-close {
      top: 12px;
      inset-inline-end: 12px;
    }

    div.error {
      color: $red;
    }
  }
}

.ant-modal.workspace-selector {
  .ant-modal-content {
    padding: 12px 16px;

    .ant-modal-confirm-body {
      .anticon.anticon-info-circle {
        opacity: 0;
        margin-right: 0;
        width: 1px;
      }

      .ant-modal-confirm-content {
        margin-inline-start: 1px;
        max-width: calc(100% - 1px);

        .ant-menu-item {
          padding-inline: 8px;

          .workspace-item {
            display: flex;
            align-items: center;
            gap: 0.5em;
          }
        }
      }
    }

    .ant-modal-confirm-btns {
      display: none;
    }
  }
}

