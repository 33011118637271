
.payments.view {
  .upload-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1000;
  }


  .filter-form {
    .filter-fields {
      .filter-field {
        &.startDate,
        &.endDate {
          //width: 150px;

          .ant-picker {
            width: 100%;
          }
        }
      }
    }
  }

  .ant-table {
    border-collapse: collapse;

    tr {
      td {
        &:first-child {
          border-left: 1px solid rgb(240, 240, 240);
        }

        &:not(:last-child) {
          border-right: 1px solid rgb(240, 240, 240);
        }


        &.date {
          width: 150px;
          white-space: nowrap;

        }

        &.fromInn,
        &.toInn {
          width: 130px;

          .ant-typography {
            white-space: nowrap;
          }
        }

        &.bankName,
        &.fromName,
        &.toName {
          min-width: 280px;
          max-width: 360px;
        }

        &.toAccount {
          white-space: nowrap;
          //width: 220px;
          //min-width: 220px;
        }

        &.account {
          //width: 160px;
          min-width: 120px;
          //max-width: 180px;
          white-space: nowrap;
        }

        &.fromName,
        &.toName {
          word-break: break-all;
        }

        &.incomingSum {
          min-width: 110px;
          max-width: 150px;

          .ant-typography {
            white-space: nowrap;
          }
        }

        &.account {


          .payment-type {
            white-space: nowrap;
            display: flex;
            align-items: center;
            gap: 0.3em;

            .ant-btn.edit-btn {
              border: none;
              //margin-left: 1em;
              width: 14px;
              height: 14px;
              padding: 0;
              //margin-left: 0.5em;

              .anticon {
                vertical-align: 0;
              }

              &:not(:first-child) {
                margin-left: 0.5em;
              }
            }

          }
        }

        &.purpose {
          max-width: 800px;
          min-width: 200px;
          //word-break: break-all;
        }

        &.date {
          .time {
            color: #185dbd;
          }
        }

        &.taxable {
          .YES {
            color: #c91c80;
          }
        }

        &.toAccount {
          a.ant-typography {
            color: inherit;
            text-decoration: underline dashed rgb(220, 220, 220);
            text-underline-offset: 3px;

            &:hover {
              color: #6694bd;
            }

            &:active {
              color: #2763a1;
            }

          }

        }


      }

    }
  }
}

.ant-select-dropdown {
  .account.special {
    .account-number {
      color: #3e1ef0;
    }
  }
}
